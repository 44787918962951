import { G } from '@mobily/ts-belt';
import type { GetStaticProps, NextPage } from 'next';
import { z } from 'zod';
import { ModularPageWrapper } from '../../components/ModularPageWrapper';
import { modularPageWrapperSchema } from '../../components/ModularPageWrapper/schema';
import { serverLogger } from '../../utils/loggers/server';
import { getCachedEntryByUrl } from '../../utils/requests/contentstack/cachedRequests';

export type StitchFixVsCompetitorsPageProps = z.infer<
  typeof modularPageWrapperSchema
>;

export const getStaticProps: GetStaticProps<
  StitchFixVsCompetitorsPageProps
> = async ({ locale }) => {
  const pageUrl = '/stitch-fix-vs-competitors';

  const entryRequest = getCachedEntryByUrl({
    contentType: 'modular_page',
    url: pageUrl,
    language: locale,
    schema: modularPageWrapperSchema,
    logger: serverLogger,
  });

  let entryResponse;

  try {
    entryResponse = await entryRequest;
  } catch (error: unknown) {
    serverLogger({
      /**
       * not well-documented, but 141 is the code contentstack returns when an
       * undefined entry is requested. At time of comment, it is accompanied
       * by "The requested entry doesn't exist" text.
       */
      level: G.isObject(error) && error?.error_code === 141 ? 'warn' : 'error',
      team: 'client-onboarding',
      message: `Failed to fetch page data - ${pageUrl}`,
      context: {
        error,
        page: pageUrl,
        url: pageUrl,
      },
    });
  }

  if (G.isUndefined(entryResponse)) {
    serverLogger({
      level: 'warn',
      team: 'client-onboarding',
      message: `Required page data is undefined - ${pageUrl}`,
      context: {
        page: pageUrl,
        url: pageUrl,
      },
    });

    throw new Error(
      'Required data not provided to render Stitch Fix vs. Competitors page',
    );
  }

  return {
    props: { ...entryResponse.data },
  };
};
const StitchFixVsCompetitorsPage: NextPage<StitchFixVsCompetitorsPageProps> = ({
  seoData,
  modules,
}) => {
  return <ModularPageWrapper seoData={seoData} modules={modules} />;
};

export default StitchFixVsCompetitorsPage;
